import React from "react";
import {Link} from "gatsby";
import {MDXProvider} from "@mdx-js/react";
import {Box, TeamBox} from "../styles/basics";
import Layout from "./Layout";
import ReadMore, {ReadMoreProvider} from "./ReadMore";

const ExternalOrInternalLink = ({href, children, ...rest}) =>
  href.indexOf("http") === 0 ? (
    <a href={href} rel="noreferrer noopener" target="_blank" {...rest}>
      {children}
    </a>
  ) : (
    <Link to={href} {...rest}>
      {children}
    </Link>
  );

const MdxLayout = ({children, pageContext, ...props}) => (
  <Layout title={pageContext.frontmatter.title} lang={pageContext.frontmatter.lang} {...props}>
    <ReadMoreProvider>
      <MDXProvider
        components={{
          div: ({isBox, isReadmore, ...rest}) =>
            isBox ? (
              pageContext.frontmatter.pageType === "team" ? (
                <TeamBox {...rest} />
              ) : (
                <Box {...rest} noCustomFont={pageContext.frontmatter.lang === "tu"} />
              )
            ) : isReadmore ? (
              <ReadMore {...rest} />
            ) : (
              <div {...rest} />
            ),
          a: ExternalOrInternalLink,
        }}
      >
        {children}
      </MDXProvider>
    </ReadMoreProvider>
  </Layout>
);

export default MdxLayout;
