import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/circleci/choice/src/components/MdxLayout.js";
import TeamMember from "../../components/TeamMember";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <div {...{
      "isBox": true,
      "flipKey": 116
    }}><h1 parentName="div">{`Projektleitung`}</h1>
      <div className="team-container">
  <TeamMember image="profile-thomas.jpg" name="Thomas Koinzer" title="Prof. Dr." university="Humboldt-Universität zu Berlin" homepage="https://www.erziehungswissenschaften.hu-berlin.de/de/institut/abteilungen/didaktik/mitarb/koinzer" email="thomas.koinzer@hu-berlin.de" mdxType="TeamMember" />
  <TeamMember image="profile-sabine.jpg" name="Sabine Gruehn" title="Prof. Dr." university="Westfälische Wilhelms-Universität Münster" homepage="http://www.uni-muenster.de/EW/personen/gruehn.shtml" email="sabine.gruehn@uni-muenster.de" mdxType="TeamMember" />
      </div>
    </div><div {...{
      "isBox": true,
      "flipKey": 117
    }}>
      <h1 parentName="div">{`Projekt-Mitarbeiterinnen`}</h1>
      <div className="team-container">
  <TeamMember image="profile-tanja.jpg" name="Dr. Tanja Mayer" university="Humboldt-Universität zu Berlin" homepage="https://www.erziehungswissenschaften.hu-berlin.de/de/institut/abteilungen/didaktik/mitarb/mitarbeiter-innen-und-lehrkraefte-fuer-besondere-aufgaben/dr-tanja-mayer" email="tanja.mayer@hu-berlin.de" imageAttribution="Martin Ibold (HU Berlin)" mdxType="TeamMember" />
  <TeamMember image="profile-judith.jpg" name="Judith Schwarz" university="Humboldt-Universität zu Berlin (zuvor WWU Münster)" homepage="https://www.erziehungswissenschaften.hu-berlin.de/de/institut/abteilungen/didaktik/mitarb/mitarbeiter-innen-und-lehrkraefte-fuer-besondere-aufgaben/judith-schwarz" email="j.schwarz@hu-berlin.de" mdxType="TeamMember" />
      </div>
    </div><div {...{
      "isBox": true,
      "flipKey": 118
    }}>
      <h1 parentName="div">{`Assoziierter Mitarbeiter`}</h1>
      <div className="team-container">
  <TeamMember image="profile-nicky-sw.jpg" name="Nicky Zunker" homepage="https://www.erziehungswissenschaften.hu-berlin.de/de/institut/abteilungen/didaktik/mitarb/mitarbeiter-innen-und-lehrkraefte-fuer-besondere-aufgaben/nicky-zunker" email="nicky.zunker@hu-berlin.de" mdxType="TeamMember" />
  <TeamMember image="profile-philipp-sw.jpg" name="Philipp Stirner" homepage="https://www.uni-muenster.de/EW/en/personen/stirner.shtml" email="philipp.stirner@uni-muenster.de" mdxType="TeamMember" />
      </div>
    </div><div {...{
      "isBox": true,
      "flipKey": 119
    }}>
      <h1 parentName="div">{`Ehemalige assoziierte Mitarbeiterinnen`}</h1>
      <div className="team-container">
  <TeamMember image="profile-carolin.jpg" name="Carolin Reimann (bis 2018)" email="reimann.carolin@fu-berlin.de" mdxType="TeamMember" />
  <TeamMember image="profile-corinna.jpg" name="Corinna Habeck (bis 2019)" email="corinna.habeck@uni-muenster.de" mdxType="TeamMember" />
      </div>
    </div>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      